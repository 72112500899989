class Gallery
  constructor: (gallery) ->
    @gallery = gallery
    @gallery_items = gallery.querySelectorAll('.gallery_item')
    @gallery_container = gallery.querySelector('.gallery_container')
    @gallery_thumbnails = gallery.querySelectorAll('.gallery_thumbnail')
    @gallery_next_link = gallery.querySelector('.gallery_navigation_next_link')
    @gallery_previous_link = gallery.querySelector('.gallery_navigation_previous_link')
    @distance_to_move = 0
    @initial_distance_to_move = 0

    @clone_originals()
    @setup_initial_values()
    @add_listeners()

  setup_initial_values: ->
    @gallery_items[0].setAttribute('data-current-item', true)
    @gallery_items[0].classList.add('current')

  add_listeners: ->
    if @gallery_next_link
      @gallery_next_link.addEventListener 'click', (e) =>
        e.preventDefault()
        
        current_item_index = @return_current_item()
        @change_item(current_item_index, current_item_index + 1)

    if @gallery_previous_link
      @gallery_previous_link.addEventListener 'click', (e) =>
        e.preventDefault()

        current_item_index = @return_current_item()
        @change_item(current_item_index, current_item_index - 1)
    
    @gallery_thumbnails.forEach (thumbnail) =>
      thumbnail.addEventListener 'click', (e) =>
        e.preventDefault()
    
    @gallery_thumbnails.forEach (thumbnail) =>
      thumbnail.addEventListener 'mousedown', (e) =>
        e.preventDefault()

        current_item_index = @return_current_item()
        @change_item(current_item_index, parseInt(thumbnail.dataset.thumbnail))

  change_item: (@current_item_index, @new_item_index) ->

    return if @current_item_index == @new_item_index

    @gallery_items.forEach (item) =>
      item.removeAttribute('data-current-item')
      item.classList.remove('current')

    if @new_item_index < 0
      cloned_item = @all_gallery_items[1]
      cloned_item.classList.add('current')
      @new_item_index = @gallery_items.length - 1
      @gallery_items[@new_item_index].setAttribute('data-current-item', true)
      @gallery_items[@new_item_index].classList.add('current')

      @distance_to_move = @distance_to_move - ((@gallery_items[@current_item_index].getBoundingClientRect().width + @gallery_items[@new_item_index].getBoundingClientRect().width) / 2)

      tl = gsap.timeline()

      tl.to(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1), duration: 0})

      @distance_to_move = 0
      @gallery_items.forEach (item, idx) =>
        unless idx == @gallery_items.length - 1
          @distance_to_move = @distance_to_move + ((@gallery_items[idx].getBoundingClientRect().width + @gallery_items[idx + 1].getBoundingClientRect().width) / 2)

      tl.set(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1)})

      setTimeout ->
        cloned_item.classList.remove('current')
      , 1000

    else if @new_item_index >= @gallery_items.length
      cloned_item = @all_gallery_items[@all_gallery_items.length - 2]
      cloned_item.classList.add('current')
      
      @new_item_index = 0
      @gallery_items[@new_item_index].setAttribute('data-current-item', true)
      @gallery_items[@new_item_index].classList.add('current')
      @distance_to_move = @distance_to_move + ((@gallery_items[@current_item_index].getBoundingClientRect().width + @gallery_items[@new_item_index].getBoundingClientRect().width) / 2)

      tl = gsap.timeline()

      tl.to(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1), duration: 0})
      @distance_to_move = 0
      tl.set(@all_gallery_items, {x: @initial_distance_to_move * -1})

      setTimeout ->
        cloned_item.classList.remove('current')
      , 1000

    else if @new_item_index > @current_item_index
      @gallery_items[@new_item_index].setAttribute('data-current-item', true)
      @gallery_items[@new_item_index].classList.add('current')

      @distance_to_move = @distance_to_move + (@gallery_items[@current_item_index].getBoundingClientRect().width * (@new_item_index - @current_item_index))

      gsap.to(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1), duration: 0})

    else if @new_item_index < @current_item_index
      @gallery_items[@new_item_index].setAttribute('data-current-item', true)
      @gallery_items[@new_item_index].classList.add('current')

      @distance_to_move = @distance_to_move - (@gallery_items[@current_item_index].getBoundingClientRect().width * (@current_item_index - @new_item_index))

      gsap.to(@all_gallery_items, {x: ((@initial_distance_to_move + @distance_to_move) * -1), duration: 0})

    @gallery_thumbnails.forEach (thumbnail) =>
      thumbnail.classList.remove('current')

    @gallery_thumbnails[@new_item_index].classList.add('current')
    
    @update_count()

  update_count: ->
    @gallery.querySelector('.gallery_count_current').textContent = @new_item_index + 1
  
  return_current_item: ->
    return Array.from(@gallery_items).findIndex((item) -> item.hasAttribute('data-current-item'))

  clone_originals: =>
    @first_two_gallery_items = Array.from(@gallery_items).slice(0, 2)
    @last_two_gallery_items = Array.from(@gallery_items).slice(-2).reverse()

    @first_two_gallery_items.forEach (item) =>
      @gallery_container.appendChild(item.cloneNode(true))

    @last_two_gallery_items.forEach (item, idx) =>
      @gallery_container.insertBefore(item.cloneNode(true), @gallery_container.firstChild)

      if @last_two_gallery_items.length == 2
        if idx == 0
          @initial_distance_to_move = @initial_distance_to_move + item.getBoundingClientRect().width

        else
          @initial_distance_to_move = @initial_distance_to_move + ((item.getBoundingClientRect().width + @gallery_items[0].getBoundingClientRect().width) / 2)

      else
        @initial_distance_to_move = @initial_distance_to_move + ((item.getBoundingClientRect().width + @gallery_items[0].getBoundingClientRect().width) / 2)

    @all_gallery_items = @gallery.querySelectorAll('.gallery_item')
    gsap.set(@all_gallery_items, {x: @initial_distance_to_move * -1})

window.agescotland ||= {}

agescotland.gallery =
  setup: ->
    document.querySelectorAll('.gallery').forEach (gallery) ->
      gallery = new Gallery(gallery)