window.agescotland ||= {}

agescotland.accordion =
  setup: ->
    @add_listeners()

  add_listeners: ->
    window.addEventListener 'resize', ->
      document.querySelectorAll('.accordion_content_block').forEach (block) ->
        content = block.querySelector('.accordion_content')

        if content.style.maxHeight
          content.style.maxHeight = "#{content.scrollHeight}px"

    document.querySelectorAll('.accordion_content_block').forEach (block) ->
      link = block.querySelector('a')
      content = block.querySelector('.accordion_content')

      block.querySelectorAll('.ugc a').forEach (link) ->
        link.setAttribute('tabindex', '-1')

      if link
        link.addEventListener 'click', (e) =>

          e.preventDefault()
          block.classList.toggle('open')
          link.setAttribute 'aria-expanded', if link.getAttribute('aria-expanded') == 'true' then 'false' else 'true'
          content.setAttribute 'aria-hidden', if content.getAttribute('aria-hidden') == 'true' then 'false' else 'true'

          block.querySelectorAll('.ugc a').forEach (a) ->
            a.setAttribute 'tabindex', if link.getAttribute('aria-expanded') == 'true' then '0' else '-1'

          tl = gsap.timeline()

          if block.classList.contains('open')
            tl.to(block.querySelector(".accordion_icon svg"), {duration: 0.4, rotate: 360, transformOrigin: 'center'})
            tl.to(block.querySelector(".accordion_icon rect:last-child"), {duration: 0.2, rotate: 180, transformOrigin: 'center'}, 0.1)
          else
            tl.to(block.querySelector(".accordion_icon svg"), {duration: 0.4, rotate: 0, transformOrigin: 'center'})
            tl.to(block.querySelector(".accordion_icon rect:last-child"), {duration: 0.2, rotate: 90, transformOrigin: 'center'}, 0.1)

          if content.style.maxHeight
            content.style.maxHeight = null
          else
            content.style.maxHeight = "#{content.scrollHeight}px"