window.agescotland ||= {}

agescotland.nested_forms =
  setup: ->
    @addListeners()

  addListeners: ->
    nestedForms = document.querySelectorAll('.nested_form')
    return false unless nestedForms

    nestedForms.forEach (field) ->
      agescotland.nested_forms.setupAddButtons(field)
      agescotland.nested_forms.setupRemoveButtons(field)

  setupAddButtons: (field) ->
    addButton = field.querySelector('.nested_form_add')
    if addButton
      addButton.addEventListener 'click', (e) =>
        agescotland.nested_forms.add(field, e)
        agescotland.nested_forms.setup_fields(e.target.closest('.nested_form'))

  setupRemoveButtons: (field) ->
    removeButtons = field.querySelectorAll('.nested_form_remove')
    if removeButtons
      removeButtons.forEach (button) ->
        button.addEventListener 'click', (e) =>
          wrapper = e.target.closest('.nested_form')
          agescotland.nested_forms.remove(field, e)
          agescotland.nested_forms.setup_fields(wrapper)

  add: (field, e) ->
    e.preventDefault()
    name = e.target.dataset.nestedFormName
    agescotland.nested_forms.insertNewContent(field, name)
    agescotland.nested_forms.setupRemoveButtons(field)

  remove: (field, e) ->
    e.preventDefault()
    agescotland.nested_forms.removeContent(e.target.closest('.nested_form_wrapper'))

  setup_fields: (wrapper) ->
    if (wrapper)
      fields = wrapper.querySelectorAll('.nested_form_wrapper')
      fields.forEach (field, index) ->
        first_fields = field.querySelectorAll('.first_only')
        display = if index == 0 then 'block' else 'none'

        first_fields.forEach (first_field) =>
          first_field.style.display = display

  insertNewContent: (field, name) ->
    template = field.querySelector('template#' + name)
    timestamp = new Date().getTime().toString()
    content = template.innerHTML.replace(/NEW_RECORD/gi, timestamp)
    target = field.querySelector('.nested_form_target')
    target.insertAdjacentHTML('beforeend', content)

  removeContent: (wrapper) ->
    if (wrapper.dataset.newRecord == 'true')
      wrapper.remove()
    else
      wrapper.style.display = 'none'
      input = wrapper.querySelector('input[name*="_destroy"]')
      input.value = '1'
      input.removeAttribute('disabled')