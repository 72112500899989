window.agescotland ||= {}

agescotland.publication =
  setup: ->
    @add_listeners()

  add_listeners: ->
    bag_forms = document.querySelectorAll('.add_to_bag_form, .bag_item tr')

    return false unless bag_forms

    bag_forms.forEach (form) ->
      form.addEventListener('submit', agescotland.publication.formSubmit)

  formSubmit: (e) ->
    e.preventDefault()
    form = e.target
    formData = new FormData(form)

    fetch(form.action,
      method: form.method,
      body: formData
      ).then((response) ->
        response.json())
      .then((json) ->
        agescotland.publication.update_elements_with(json)
      )['catch'] (error) ->
        console.log error

  update_elements_with: (data) ->
    bagMessage = document.querySelector('.bag_message')
    bagMessageSpan = bagMessage.querySelector('.message')
    bagOverview = document.querySelector('.bag_overview')
    if bagOverview
      bagCount = bagOverview.querySelector('.bag_count')
      bagType = bagOverview.querySelector('.bag_type')
      bagText = bagType.dataset.text

    if data.count
      if bagCount
        bagCount.textContent = data.count

      if bagType
        if data.count > 1
          bagType.textContent = bagText + "s"
        else
          bagType.textContent = bagText

    if data.errors
      if bagMessageSpan
        bagMessageSpan.innerHTML = data.errors
      else
        bagMessage.innerHTML = data.errors
      bagMessage.classList.remove('success')
      bagMessage.classList.add('error')
      bagMessage.style.display = 'flex'
    else if data.message
      if bagMessageSpan
        bagMessageSpan.innerHTML = data.message
      else
        bagMessage.innerHTML = data.message
      bagMessage.classList.remove('error')
      bagMessage.classList.add('success')
      bagMessage.style.display = 'flex'

      if bagOverview
        bagOverview.classList.add('item_added')

        setTimeout ->
          bagOverview.classList.remove('item_added')
        , 1000


