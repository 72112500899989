window.agescotland ||= {}

agescotland.donate =
  setup: ->
    @setup_donation_texts()
  
  setup_donation_texts: ->
    donation_texts = document.querySelectorAll('.donation_amount_text')

    return false unless donation_texts
    
    document.querySelectorAll('.donate_form').forEach (form) ->
      form.querySelectorAll('.donation_amounts label').forEach (label, idx) ->
        label.addEventListener 'click', (e) =>
          donation_texts = form.querySelectorAll('.donation_amount_text')
          donation_texts.forEach (text) ->
            text.classList.remove('show')

          if donation_texts[idx]
            donation_texts[idx].classList.add('show')
            # document.querySelectorAll('.donation_amount_text')[idx].style.opacity = 1
          
