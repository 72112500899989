window.agescotland ||= {}

agescotland.attendee_fields =
  setup: ->
    @add_listeners()

  add_listeners: ->
    attendee_checkbox = document.getElementById('order_fundraising_event_booking_attributes_book_on_behalf')

    return false unless attendee_checkbox

    if attendee_checkbox.checked
      agescotland.attendee_fields.changeLabels(attendee_checkbox.checked)
    attendee_checkbox.addEventListener 'change', (e) =>
      agescotland.attendee_fields.changeLabels(e.target.checked)

  changeLabels: (value) ->
    attendeeFields = document.querySelectorAll('.attendee_field')
    return false unless attendeeFields

    if value
      attendeeFields.forEach (field) ->
        label = field.querySelector('label')
        label.prepend('Attendees ')
    else
      attendeeFields.forEach (field) ->
        label = field.querySelector('label')
        label.innerHTML = label.innerHTML.replace('Attendees ', '')

