window.agescotland ||= {}

agescotland.nested_form_count =
  setup: ->
    return if document.querySelectorAll('.nested_model_fields').length <= 0
    @add_listeners()
    @update_count()

  add_listeners: ->
    add_buttons = document.querySelectorAll('.nested_buttons a')
    if add_buttons
      add_buttons.forEach (btn) =>
        btn.addEventListener 'click', @create_nested_field
    remove_links = document.querySelectorAll('.nested_model_item a.remove')
    if remove_links
      remove_links.forEach (link) =>
        link.addEventListener 'click', @remove_nested_field

    agescotland.nested_form_count.setup_first_field()
    useDetailFields = document.querySelectorAll(".nested_model_item .use_your_details input[type=checkbox]")
    if useDetailFields
      useDetailFields.forEach (field) =>
        field.addEventListener 'change', @setup_user_data

  create_nested_field: (e) ->
    e.preventDefault()

    wrapper = e.target.closest('.nested_model_wrapper')
    nestedItems = wrapper.querySelectorAll(".nested_model_item:not([style='display: none;']")
    count = e.target.dataset.count
    return if  nestedItems.length >= count

    template = wrapper.querySelector('template')
    timestamp = new Date().getTime().toString()
    content = template.innerHTML.replace(/NEW_RECORD/gi, timestamp)
    wrapper.querySelector('.nested_model_fields').insertAdjacentHTML('beforeend', content)

    agescotland.nested_form_count.setup_first_field()
    agescotland.nested_form_count.add_listeners()
    agescotland.nested_form_count.update_count()
    agescotland.custom_select.setup()
    agescotland.form_show_hide.setup()
    agescotland.custom_checkbox.setup()
    agescotland.custom_radio.setup()

  remove_nested_field: (e) ->
    e.preventDefault()

    wrapper = e.target.closest('.nested_model_item')
    if (wrapper.dataset.newRecord == 'true')
      wrapper.remove()
    else
      wrapper.style.display = 'none'
      input = wrapper.querySelector('input[name*="_destroy"]')
      input.value = '1'
      input.removeAttribute('disabled')

    agescotland.nested_form_count.setup_first_field()
    agescotland.nested_form_count.update_count()

  setup_first_field: ->
    nestedItems = document.querySelectorAll(".nested_model_item:not([style='display: none;']")
    return if nestedItems.length <= 0

    useDetailFields = document.querySelectorAll(".use_your_details")
    return if useDetailFields.length <= 0

    useDetailFields.forEach (field, idx) =>
      field.style.display = 'none'
    nestedItems[0].querySelector('.use_your_details').style.display = 'block'

  setup_user_data: (e) ->
    wrapper = e.target.closest('.nested_model_item')
    useDetails = e.target.checked

    if useDetails
      wrapper.querySelector('.title_field select').value = wrapper.querySelector('#user_title').value
      wrapper.querySelector('.first_name_field input').value = wrapper.querySelector('#user_first_name').value
      wrapper.querySelector('.last_name_field input').value = wrapper.querySelector('#user_last_name').value
      wrapper.querySelector('.email_field input').value = wrapper.querySelector('#user_email').value
      if wrapper.querySelector('.job_title_field input')
        wrapper.querySelector('.job_title_field input').value = wrapper.querySelector('#user_job_title').value
      if wrapper.querySelector('.organisation_field input')
        wrapper.querySelector('.organisation_field input').value = wrapper.querySelector('#user_organisation').value
      wrapper.querySelector('.title_field select').dispatchEvent(new Event('change'))

  update_count: ->
    wrappers = document.querySelectorAll('.nested_model_wrapper')
    return if wrappers.length <= 0

    wrappers.forEach (wrapper) =>
      nestedItems = wrapper.querySelectorAll(".nested_model_item:not([style='display: none;']")
      return if nestedItems.length <= 0

      nestedItems.forEach (item, idx) =>
        item.querySelector('.attendee_count').innerHTML = idx + 1

      button = wrapper.querySelector('.nested_buttons a')
      count = button.dataset.count
      if nestedItems.length >= count
        button.disabled = true
        button.classList.add('disabled')
      else
        button.disabled = false
        button.classList.remove('disabled')
