class StripeCheckout {
  constructor() {
    const form = document.querySelector("#payment-form")
    const stripeKey = form.dataset.publickey;
    const formURL = form.action;
    const intentURL = form.dataset.intent;
    const completeURL = form.dataset.complete;
    let orderBillingDetails;

    const stripe = Stripe(stripeKey, { apiVersion: '2023-10-16' });
    let elements;

    initialize();

    document.querySelector("#payment-form").addEventListener("submit", handleSubmit);

    // Fetches a payment intent and captures the client secret
    async function initialize() {
      try {
        const response = await fetch(intentURL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ }),
        });
        const { clientSecret, paymentError, billingDetails } = await response.json();

        if (paymentError) {
          showMessage(paymentError);
          return;
        }

        orderBillingDetails = billingDetails;

        let defaultRules
        let wideRules
        let widerRules
        let extrawideRules

        defaultRules = {
          '.Tab': {
            border: '2px solid #CDCCCC',
          },

          '.Tab--selected': {
            border: '2px solid #141760',
          },

          '.Tab:focus, .Tab:hover, .Tab:active': {
            boxShadow: 'none'
          },

          '.TabLabel': {
            fontFamily: 'FSMeBold, Arial, sans-serif',
          },

          '.TabLabel--selected': {
            color: '#141760',
          },

          '.TabIcon--selected': {
            fill: '#141760',
          },

          '.Label': {
            fontFamily: 'FSMeBold, Arial, sans-serif',
            fontSize: '17px',
            lineHeight: '27px',
            marginBottom: '6px'
          },

          '.Input': {
            fontFamily: 'FSMeLight, Arial, sans-serif',
            outline: '1px solid #CDCCCC',
            border: '0px',
            fontSize: '17px',
            lineHeight: '27px',
            padding: '10.5px 14px 6.5px'
          },

          '.Input:focus': {
            outline: '2px solid #141760',
            boxShadow: 'none'
          },

          '.Error': {
            fontFamily: 'FSMeLight, Arial, sans-serif',
            color: '#D90000',
            fontSize: '14px',
            lineHeight: '19px',
          }
        }

        if (window.innerWidth >= 850 && window.innerWidth < 1024) {
          wideRules = {
            '.Error': {
              fontFamily: 'FSMeLight, Arial, sans-serif',
              color: '#BE0000',
              fontSize: '17px',
              lineHeight: '23px',
            }
          }
        }
        else if (window.innerWidth >= 1024 && window.innerWidth < 1280) {
          widerRules = {
            '.Label': {
              fontFamily: 'FSMeBold, Arial, sans-serif',
              fontSize: '20px',
              lineHeight: '32px',
              marginBottom: '6px'
            },
            
            '.Input': {
              fontFamily: 'FSMeLight, Arial, sans-serif',
              outline: '1px solid #CDCCCC',
              fontSize: '20px',
              border: '0px',
              lineHeight: '32px',
              padding: '8px 12px 4px'
            },

            '.Error': {
              fontFamily: 'FSMeLight, Arial, sans-serif',
              color: '#BE0000',
              fontSize: '17px',
              lineHeight: '23px',
            }
          }
        }
        else if (window.innerWidth >= 1280) {
          extrawideRules = {
            '.Label': {
              fontFamily: 'FSMeBold, Arial, sans-serif',
              fontSize: '20px',
              lineHeight: '32px',
              marginBottom: '6px'
            },

            '.Input': {
              fontFamily: 'FSMeLight, Arial, sans-serif',
              outline: '1px solid #CDCCCC',
              fontSize: '20px',
              border: '0px',
              lineHeight: '32px',
              padding: '11px 12px 7px'
            },

            '.Error': {
              fontFamily: 'FSMeLight, Arial, sans-serif',
              color: '#BE0000',
              fontSize: '17px',
              lineHeight: '23px',
            }
          }
        }

        let rules = {
          ...defaultRules,
          ...wideRules,
          ...widerRules,
          ...extrawideRules
        }

        const appearance = {
          theme: 'stripe',
          variables: {
            colorText: '#545454'
          },
          rules: rules
        };

        elements = stripe.elements({
          fonts: [
            {
              cssSrc: 'https://cdn.fonts.net/kit/149e417d-d916-4d89-9d87-afd3d1f4f573/149e417d-d916-4d89-9d87-afd3d1f4f573.css',
            }
          ],
          appearance: appearance,
          clientSecret: clientSecret
        });

        const paymentElementOptions = {
          layout: "tabs",
          defaultValues: {
            billingDetails: orderBillingDetails
          },
        };

        const paymentElement = elements.create("payment", paymentElementOptions);
        paymentElement.mount("#payment-element");
      } catch (error) {
        showMessage("An unexpected error occurred.");
      }
    }

    async function handleSubmit(e) {
      e.preventDefault();
      setLoading(true);

      try {
        // Check order status first
        const statusResponse = await fetch(formURL, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({}),
        });
        const { redirectURL } = await statusResponse.json();

        if (redirectURL) {
          window.location.href = redirectURL;
          return;
        }

        const { error } = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: completeURL,
            payment_method_data: {
              billing_details: orderBillingDetails
            }
          },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error && ["card_error", "validation_error", "invalid_request_error"].includes(error.type)) {
          showMessage(error.message);
        } else {
          showMessage("An unexpected error occurred.");
        }
      } catch (error) {
        showMessage("An unexpected error occurred.");
      } finally {
        setLoading(false);
      }
    }

    function showMessage(messageText) {
      const messageContainer = document.querySelector("#payment-message");
      messageContainer.classList.remove("hidden");
      messageContainer.textContent = messageText;
    }

    function setLoading(isLoading) {
      document.querySelector(".payment_submit_button").disabled = isLoading;
      document.querySelector(".payment_submit_button").classList.toggle("hidden", !isLoading);
    }
  }
}

window.agescotland || (window.agescotland = {});

agescotland.stripe = {
  setup: function () {
    if ((typeof Stripe !== "undefined" && Stripe !== null)) {
      new StripeCheckout();
    }
  }
};