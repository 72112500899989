window.agescotland ||= {}

agescotland.bag =
  setup: ->
    @add_listeners()

  add_listeners: ->
    bagForm = document.querySelector('.bag_form')

    return false unless bagForm

    bagForm.addEventListener('submit', agescotland.bag.formSubmit)

    removeLinks = document.querySelectorAll('.remove_link')
    removeLinks.forEach (link) ->
      link.addEventListener('click', agescotland.bag.removeItem)

    qtyInputs = document.querySelectorAll('input[id^=quantities]')
    qtyInputs.forEach (link) ->
      link.addEventListener('change', agescotland.bag.formSubmit)


    updateButton = document.getElementById('update_basket')
    updateButton.style.display = 'none'

  removeItem: (e) ->
    e.preventDefault()
    link = e.target
    fetch(link.href,
      headers:  {
        'X-Requested-With': 'XMLHttpRequest'
      })
      .then((response) ->
        response.json()
      )
      .then((json) ->
        agescotland.bag.update_elements_with(json)
        agescotland.bag.setup()
      )['catch'] (error) ->
        console.log error

  formSubmit: (e) ->
    e.preventDefault()
    form = document.querySelector('.bag_form')
    formData = new FormData(form)

    fetch(form.action,
      method: form.method,
      body: formData
      ).then((response) ->
        response.json())
      .then((json) ->
        agescotland.bag.update_elements_with(json)
        agescotland.bag.setup()
      )['catch'] (error) ->
        console.log error

  update_elements_with: (data) ->
    if data.content
      document.getElementById('basket_ajax_wrapper').innerHTML = data.content
