window.agescotland ||= {}

agescotland.form_show_hide =
  setup: ->
    @add_listeners()

  add_listeners: ->
    additional_fields = document.querySelectorAll('.additional_field')

    return false unless additional_fields

    additional_fields.forEach (field) ->
      selectField = field.querySelector('select:not(#delivery_fields select)')
      if selectField
        agescotland.form_show_hide.showHideField(field, selectField.value)
        selectField.addEventListener 'change', (e) =>
          agescotland.form_show_hide.showHideField(field, e.target.value)

      radioButtons = field.querySelectorAll('.radio_button:not(.detail_field .radio_button)')
      if radioButtons
        radioButtons.forEach (button) ->
          if button.checked
            agescotland.form_show_hide.showHideField(field, button.value)
          button.addEventListener 'change', (e) =>
            agescotland.form_show_hide.showHideField(field, e.target.value)

      checkButtons = field.querySelectorAll('input[type=checkbox]:not(.detail_field input[type=checkbox])')
      if checkButtons
        checkButtons.forEach (button) ->
          if button.checked
            agescotland.form_show_hide.showHideField(field, button.value)
          button.addEventListener 'change', (e) =>
            if button.checked
              agescotland.form_show_hide.showHideField(field, e.target.value)
            else
              agescotland.form_show_hide.showHideField(field, 'none')

    nested_additional_field = document.querySelectorAll('.nested_additional_field')
    return false unless nested_additional_field

    nested_additional_field.forEach (field) ->
      checkButtons = field.querySelectorAll('input:not(.detail_field input)')
      if checkButtons
        checkButtons.forEach (button) ->
          if button.checked
            agescotland.form_show_hide.showHideField(field, true)
          button.addEventListener 'change', (e) =>
            agescotland.form_show_hide.showHideField(field, e.target.checked)

  showHideField: (field, value) ->
    detailField = field.querySelector('.detail_field')
    alternateDetailField = field.querySelector('.alternate_detail_field')
    return false unless detailField

    if value && field.dataset.detailOptions.includes(value)
      detailField.classList.remove('hidden_field')
      if alternateDetailField
        alternateDetailField.classList.add('hidden_field')
        alternateDetailInput = alternateDetailField.querySelector('input, textarea, select')
        if alternateDetailInput
          if (alternateDetailInput.tagName == 'SELECT')
            alternateDetailInput.selectedIndex = 0
            alternateDetailInput.dispatchEvent(new Event('change'))
          else
            alternateDetailInput.value = ''
    else
      detailField.classList.add('hidden_field')
      detailInput = detailField.querySelector('input, textarea, select')
      if (detailInput.tagName == 'SELECT')
        detailInput.selectedIndex = 0
        detailInput.dispatchEvent(new Event('change'))
      else
        detailInput.value = ''
      if alternateDetailField
        alternateDetailField.classList.remove('hidden_field')
